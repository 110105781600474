import React from "react";
import{ Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import Button from "../CtButton";

export default class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const onSubmit = () => {
      this.props.onSubmit()
      this.props.toggleModal()
    }

    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.props.toggleModal}>
        <ModalHeader className="justify-content-center" toggle={this.props.toggleModal}>
          Are you sure?
        </ModalHeader>
        <ModalBody className="text-center">
          {this.props.message}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggleModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={onSubmit}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}