import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actions from '../actions'
import Button from './CtButton'
import TapModal from './modals/TapModal'
import { Row, Col, Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap';
import DeleteModal from './modals/DeleteModal'

const mapStateToProps = state => {
  return {
    current_location: state.auth.current_location,
    ...state.app
  }
}

class Taps extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTapID: null,
      selectedTap: null,
      modalOpen: false,
      deleteTapModalOpen: false
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.menuComponent = this.menuComponent.bind(this);
    this.deleteTapModal = this.deleteTapModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.current_location !== this.props.current_location) {
      this.props.loadTaps()
    }
  }

  componentDidMount(){
    this.props.loadTaps()
  }

  toggleModal(){
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  deleteTapModal(tapID = null) {
    if( _.isNumber(tapID) ){
      this.setState({
        selectedTapID: tapID,
        deleteTapModalOpen: !this.state.deleteTapModalOpen 
      });
    } else {
      this.setState({ deleteTapModalOpen: !this.state.deleteTapModalOpen });
    }
  }

  editTapModal(tap) {
    this.setState({
      selectedTap: tap
    }, () => {
      console.log(this.state.selectedTap)
      this.setState({ modalOpen: !this.state.modalOpen })
    });
  }

  renderTable() {
    const fireModal = (id) => this.deleteTapModal(id)
    const editModal = (tap) => this.editTapModal(tap)

    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Tap Name</th>
            <th>Brewery</th>
            <th className="text-right">Type</th>
            <th className="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.props.taps.map(function(tap){
            return (
              <tr key={tap.id}>
                <td>{tap.name}</td>
                <td>{tap.brewery}</td>
                <td className="text-right">{_.upperFirst(tap.beer_type)}</td>
                <td className="text-right">
                  <Button icon color="info" size="sm" id="item-edit" onClick={ () => editModal(tap)}>
                    <i className="now-ui-icons ui-2_settings-90"></i>
                  </Button>{` `}
                  <UncontrolledTooltip placement="top" target="item-edit" delay={{"show": 500, "hide": 0}}>Edit Tap</UncontrolledTooltip>
                  <Button icon color="danger" size="sm" id="item-delete" onClick={ () => fireModal(tap.id) }>
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </Button>
                  <UncontrolledTooltip placement="top" target="item-delete" delay={{"show": 500, "hide": 0}}>Delete Tap</UncontrolledTooltip>
                </td>
              </tr>
            )}
          )}
        </tbody>
      </Table>
    )
  }

  menuComponent() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Button className="pull-right" color="primary" size="lg" float="right" onClick={this.toggleModal}>
              New Tap <i className="now-ui-icons ui-1_simple-add"></i>
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Card className="card-calendar">
              <CardBody>
                <h1 className="menu-title">Tap List</h1>
                { this.renderTable() }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <TapModal
          setTap={ this.props.setTap }
          editTap={ this.props.editTap }
          toggleModal={ this.toggleModal }
          modalOpen={ this.state.modalOpen }
          selectedTap={ this.state.selectedTap }
        />
        <DeleteModal
          onSubmit={ () => this.props.deleteTap(this.state.selectedTapID) }
          toggleModal={ this.deleteTapModal }
          modalOpen={ this.state.deleteTapModalOpen }
          message="You would like to delete this tap?"
        />
      </div>
    )
  }

  loadingComponent() {
    return (
      <div className="main-panel">
        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Card className="card-calendar">
              <CardBody>
                <h1 className="menu-title">Loading...</h1>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    let view;
    if(this.props.taps){
      view = this.menuComponent()
    } else {
      view = this.loadingComponent()
    }
    return (
      <div className="main-panel">
        {view}
      </div>
    );
  }
}

export default connect(mapStateToProps, {...actions})(Taps);