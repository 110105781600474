import React from "react";
import _ from 'lodash';
import { Card, CardBody, Row, Col } from "reactstrap";
import { connect } from 'react-redux'
import Button from "./CtButton";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import { Link } from 'react-router-dom'
import * as actions from '../actions'
import EventModal from './modals/EventModal'

const localizer = BigCalendar.momentLocalizer(moment)

const mapStateToProps = state => {
  return state.app
}

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      register: {
        "titleState": "",
        "stateTimeState": "",
        "endTimeState": ""
      }
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.eventsList = this.eventsList.bind(this);
  }

  componentDidMount() {
    this.props.loadEvents();
    this.setState({
      modalOpen: this.props.location.pathname === "/events/new"
    })
  }

  selectedEvent(event) {
    alert(event.title);
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
      register: {
        "titleState": "",
        "stateTimeState": "",
        "endTimeState": ""
      }
    });
  }

  eventsList() {
    const colors = ["green", "red", "azure"]
    const events = []
    _.each(this.props.events, function(event) {
      events.push({
        title: event.name,
        start: moment(event.start_date).toDate(),
        end: moment(event.end_date).toDate(),
        color: colors[event.location_id - 1]
      })
    })
    return events
  }

  render() {
    return (
      <div className="main-panel">
        <div className="content">
          <Row>
            <Col xs={12} md={10} className="ml-auto mr-auto">
              <Link to="/events/new">
                <Button className="pull-right" color="primary" size="lg" float="right" onClick={this.toggleModal}>
                  Add Event <i className="now-ui-icons ui-1_simple-add"></i>
                </Button>
              </Link>
            
              <Card className="card-calendar">
                <CardBody>
                  <BigCalendar
                    selectable
                    localizer={localizer}
                    events={this.eventsList()}
                    defaultView="month"
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={event => this.selectedEvent(event)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <EventModal
          onSubmit={ this.props.setEvent }
          toggleModal={ this.toggleModal }
          modalOpen={ this.state.modalOpen }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, {...actions})(Calendar);
