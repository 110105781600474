import { AUTHENTICATED, UNAUTHENTICATED, AUTHENTICATION_ERROR, SET_USER, SET_LOCATION } from '../actions/auth';

export default function(state={}, action) {
  switch(action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false };
    case AUTHENTICATION_ERROR:
      return { ...state, error: action.payload };
    case SET_USER:
      return { ...state, user: action.user, current_location: action.user.locations[0]}
    case SET_LOCATION:
      return { ...state, current_location: action.location}
  }
  return state;
}