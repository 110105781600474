import axios from 'axios'

const instance = axios.create({
  baseURL: '',
  timeout: 1000,
  headers: { 
    'Access-Control-Allow-Origin': '*',
    'Authorization': "Bearer " + localStorage.getItem('token')
  }
});

export function getUser() {
  return instance.get("/users/me")
}

// Events
export function getEvents() {
  return instance.get("/api/events")
}

export function setEvent(event) {
  return instance.post("/api/events", event)
}

// Menus
export function getMenus(location_id) {
  return instance.get(`/api/locations/${location_id}/menus`)
}
export function setMenu(menu, location_id) {
  return instance.post(`/api/locations/${location_id}/menus`, menu)
}
export function deleteMenu(id, location_id) {
  return instance.delete(`api/locations/${location_id}/menus/${id}`)
}

// Taps
export function getTaps(location_id) {
  return instance.get(`/api/locations/${location_id}/taps`)
}
export function setTap(tap, location_id) {
  return instance.post(`/api/locations/${location_id}/taps`, tap)
}
export function deleteTap(id, location_id) {
  return instance.delete(`api/locations/${location_id}/taps/${id}`)
}

// Specials
export function getSpecials() {
  return instance.get("/api/specials")
}
export function setSpecial(special) {
  return instance.post("/api/specials", special)
}
export function deleteSpecial(id) {
  return instance.delete(`api/specials/${id}`)
}
export function updateSpecial(special) {
  return instance.put(`api/specials/${special.id}`, { "special": special })
}

// Items
export function setItem(item) {
  return instance.post("/api/items", item)
}
export function deleteItem(id) {
  return instance.delete(`api/items/${id}`)
}
