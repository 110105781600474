import React, { Component } from 'react'
import Button from "./CtButton";
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'

class NotFound extends Component {
  render () {
    return (
      <div className="full-page-content">
        <Container text>
          <h1>Page Not found</h1>
          <Link to="/">
            <Button >Back to home</Button>
          </Link>
        </Container>
      </div>
    )
  }
}

export default NotFound