import React from 'react';
import { connect } from 'react-redux'
import * as actions from '../actions'
import PanelHeader from './PanelHeader'
import Statistics from './Statistics'
import { Line } from "react-chartjs-2"
import { Row, Col, Card, CardBody,  } from 'reactstrap'

const mapStateToProps = state => {
  return {
    app: state.app,
    current_location: state.auth.current_location
  }
}

const dashboardPanelChart = {
  data: canvas => {
    const ctx = canvas.getContext("2d");
    var chartColor = "#FFFFFF";
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

    return {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC"
      ],
      datasets: [
        {
          label: "Page Views",
          borderColor: chartColor,
          pointBorderColor: chartColor,
          pointBackgroundColor: "#2c2c2c",
          pointHoverBackgroundColor: "#2c2c2c",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 1,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          data: [50, 150, 100, 190, 130, 90, 150, 160, 120, 140, 190, 95]
        }
      ]
    };
  },
  options: {
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0
      }
    },
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#fff",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    legend: {
      position: "bottom",
      fillStyle: "#FFF",
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 10
          },
          gridLines: {
            drawTicks: true,
            drawBorder: false,
            display: true,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "rgba(255,255,255,0.1)"
          },
          ticks: {
            padding: 10,
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold"
          }
        }
      ]
    }
  }
};

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="main-panel">
        <div className="content dashboard-content">
          <PanelHeader
            size="lg"
            content={
              <Line
                data={dashboardPanelChart.data}
                options={dashboardPanelChart.options}
              />
            }
          />
          <Row className="dashboard-content">
            <Col xs={12} md={10} className="ml-auto mr-auto">
              <Card className="card-stats card-raised">
                <CardBody>
                  <Row>
                    <Col xs={12} md={3}>
                      <Statistics
                        iconState="primary"
                        icon="ui-1_calendar-60"
                        title="2"
                        subtitle="Events this week"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Statistics
                        iconState="success"
                        icon="business_money-coins"
                        title={
                          <span>
                            <small>$</small>337
                          </span>
                        }
                        subtitle="Todays Revenue"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Statistics
                        iconState="info"
                        icon="objects_globe"
                        title="57"
                        subtitle="Page Views"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Statistics
                        iconState="success"
                        icon="emoticons_satisfied"
                        title="4.7"
                        subtitle="Yelp Rating"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {...actions})(Dashboard);