import React from "react";
import{ Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import Button from "../CtButton";

export default class MenuModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: {
        "title": "",
        "description": ""
      },
      menu: {
        "title": "",
        "description": ""
      }
    }

    this.registerNotEmpty = this.registerNotEmpty.bind(this);
  }

  registerNotEmpty(e, state) {
    var menu = this.state.menu;
    var register = this.state.register;
    menu[state] = e.target.value;
    this.setState({ register, menu });
  }

  formSubmit(e) {
    e.preventDefault()
    this.props.onSubmit(this.state.menu)
    this.props.toggleModal()
  }

  render() {
    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.props.toggleModal}>
        <Form onSubmit={ e => this.formSubmit(e, this.state.event) }>
          <ModalHeader className="justify-content-center" toggle={this.props.toggleModal}>
            <FormGroup className={this.state.register.name}>
              <Input placeholder="Menu Title" className="text-center" onChange={e => this.registerNotEmpty(e, "title")} value={this.state.menu.title}/>
            </FormGroup>
          </ModalHeader>
          <ModalBody>
            <FormGroup className={"has-label"}>
              <Input type="textarea" name="text" placeholder="Add description" onChange={e => this.registerNotEmpty(e, "description")} value={this.state.menu.description}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggleModal}>
                Close
            </Button>
            <Button color="primary" type="submit">
              Add
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}