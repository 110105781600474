import * as types from '../actions/types'
import _ from 'lodash'

const routes = [
  {
    "location": "/",
    "name": "Home"
  },
  {
    "location": "/events",
    "name": "Events"
  },
  {
    "location": "/menus",
    "name": "Menus"
  },
  {
    "location": "/taps",
    "name": "Taps"
  }
]

const initialState = {
  sidebarOpen: false,
  routes: routes,
  events: []
}

const actionsMap = {
  [types.SET_EVENTS]: (state, action = {events: {}}) => {
    return _.assign({}, state, {
      events: action.events
    })
  },
  [types.SET_MENUS]: (state, action = {menus: {}}) => {
    return _.assign({}, state, {
      menus: action.menus
    })
  },
  [types.SET_TAPS]: (state, action = {taps: {}}) => {
    return _.assign({}, state, {
      taps: action.taps
    })
  },
  [types.SET_SPECIALS]: (state, action = {specials: {}}) => {
    return _.assign({}, state, {
      specials: action.specials
    })
  },
  [types.ADD_EVENT]: (state, action = {event: {}}) => {
    return _.assign({}, state, {
      events: state.events.concat(action.event)
    })
  },
  [types.ADD_TAP]: (state, action = {tap: {}}) => {
    return _.assign({}, state, {
      taps: state.taps.concat(action.tap)
    })
  },
  [types.ADD_MENU]: (state, action = {menu: {}}) => {
    action.menu.items = []
    return _.assign({}, state, {
      menus: state.menus.concat(action.menu)
    })
  }
}

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type]
  return fn ? fn(state, action) : state
}