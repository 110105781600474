import React from 'react';
import ReactDOM from 'react-dom';
import './application.scss'
import decode from 'jwt-decode';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import rootReducer from './reducers'
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import Dashboard from './components/Dashboard'
import Calendar from './components/Calendar'
import Menus from './components/Menus'
import Taps from './components/Taps'
import Login from './components/Login'
import Specials from './components/Specials'
import NotFound from './components/NotFound'
import { getUser } from './actions/auth'
import requireAuth from './components/auth/requireAuth';
import noRequireAuth from './components/auth/noAuthRequired';

const middleware = applyMiddleware(thunk)
const store = createStore(rootReducer, composeWithDevTools(
  middleware
))

const user_token = localStorage.getItem('token');

if(user_token) {
  store.dispatch(getUser(user_token))
}

const App = () => (
  <Provider store={store}>
    <Router>
      <div>
        <NavBar/>
        <Route component={SideBar} />
        <Switch>
          <Route exact path='/' component={requireAuth(Dashboard)} />
          <Route path='/login' component={noRequireAuth(Login)} />
          <Route path='/events' component={requireAuth(Calendar)} />
          <Route path='/menus' component={requireAuth(Menus)} />
          <Route path='/taps' component={requireAuth(Taps)} />
          <Route path='/specials' component={requireAuth(Specials)} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  </Provider>
)

ReactDOM.render(<App />, document.getElementById('root'));
