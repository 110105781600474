import * as api from '../api'
import * as types from './types'

/* Thunk Actions */
export function loadEvents() {
  return dispatch => {
    api.getEvents().then(events => {
      dispatch(setEvents(events.data))
    })
  }
}

export function setEvent(event) {
  return dispatch => {
    api.setEvent(event).then(event => {
      dispatch(addEvent(event.data))
    })
  }
}

export function loadMenus() {
  return (dispatch, getState) => {
    let location_id = getState().auth.current_location.id
    api.getMenus(location_id).then(menus => {
      dispatch(setMenus(menus.data))
    })
  }
}

export function loadSpecials() {
  return dispatch => {
    api.getSpecials().then(specials => {
      dispatch(setSpecials(specials.data))
    })
  }
}

export function loadTaps() {
  return (dispatch, getState) => {
    let location_id = getState().auth.current_location.id
    api.getTaps(location_id).then(taps => {
      dispatch(setTaps(taps.data))
    })
  }
}

export function setMenu(menu) {
  return (dispatch, getState) => {
    let location_id = getState().auth.current_location.id
    menu.location_id = location_id
    api.setMenu(menu, location_id).then(menu => {
      dispatch(addMenu(menu.data))
    })
  }
}

export function setItem(item) {
  return dispatch => {
    api.setItem(item).then(item => {
      dispatch(loadMenus())
    })
  }
}

export function setTap(tap) {
  return (dispatch, getState) => {
    let location_id = getState().auth.current_location.id
    tap.location_id = location_id
    api.setTap(tap, location_id).then(tap => {
      dispatch(addTap(tap.data))
    })
  }
}

export function deleteMenu(id) {
  return (dispatch, getState) => {
    let location_id = getState().auth.current_location.id
    api.deleteMenu(id, location_id).then(menu => {
      dispatch(loadMenus())
    })
  }
}

export function deleteItem(id) {
  return dispatch => {
    api.deleteItem(id).then(item => {
      dispatch(loadMenus())
    })
  }
}

export function deleteTap(id) {
  return (dispatch, getState) => {
    let location_id = getState().auth.current_location.id
    api.deleteTap(id, location_id).then(tap => {
      dispatch(loadTaps())
    })
  }
}

export function deleteSpecial(id) {
  return dispatch => {
    api.deleteSpecial(id).then(special => {
      dispatch(loadSpecials())
    })
  }
}

export function updateSpecial(special) {
  return dispatch => {
    api.updateSpecial(special).then(special => {
      dispatch(loadSpecials())
    })
  }
}

/* Action Creators */
export function setEvents(events) {
  return {
    type: types.SET_EVENTS,
    events
  }
}

export function setMenus(menus) {
  return {
    type: types.SET_MENUS,
    menus
  }
}

export function setTaps(taps) {
  return {
    type: types.SET_TAPS,
    taps
  }
}

export function setSpecials(specials) {
  return {
    type: types.SET_SPECIALS,
    specials
  }
}

export function addEvent(event) {
  return {
    type: types.ADD_EVENT,
    event
  }
}

export function addTap(tap) {
  return {
    type: types.ADD_TAP,
    tap
  }
}

export function addMenu(menu) {
  return {
    type: types.ADD_MENU,
    menu
  }
}

export function addItem(item) {
  return {
    type: types.ADD_ITEM,
    item
  }
}

export function addSpecial(special) {
  return {
    type: types.ADD_SPECIAL,
    special
  }
}
