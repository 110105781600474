import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actions from '../actions'
import Button from './CtButton'
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, CardTitle, Input } from 'reactstrap';
import ItemModal from './modals/ItemModal'
import DeleteModal from './modals/DeleteModal'
import { Badge } from 'reactstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const mapStateToProps = state => {
  return state.app
}

class Specials extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedSpecialIndex: 0,
      dateModalOpen: false,
      deleteSpecialModalOpen: false,
      text: null,
      title: null
    }
    this.loadingComponent = this.loadingComponent.bind(this);
    this.dateModal = this.dateModal.bind(this);
    this.deleteSpecialModal = this.deleteSpecialModal.bind(this);
    this.editSpecialComponent = this.editSpecialComponent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.saveSpecial = this.saveSpecial.bind(this);
  }

  componentDidMount(){
    this.props.loadSpecials()
  }

  dateModal() {
    this.setState({ dateModalOpen: !this.state.dateModalOpen });
  }

  deleteSpecialModal() {
    this.setState({ deleteSpecialModalOpen: !this.state.deleteSpecialModalOpen });
  }

  deleteSpecial() {
    this.props.deleteSpecial(this.props.specials[this.state.selectedSpecialIndex].id)
    this.setState({ selectedSpecialIndex: 0 })
  }

  handleChange(value) {
    this.setState({ text: value })
  }

  handleTitleChange(value) {
    this.setState({ title: value })
  }

  saveSpecial() {
    const special = this.props.specials[this.state.selectedSpecialIndex]
    special["title"] = this.state.title
    special["body"] = this.state.text
    this.props.updateSpecial(special)
  }

  renderWYSIWYG() {
    const text = this.state.text ? this.state.text : this.props.specials[this.state.selectedSpecialIndex].body
    return <ReactQuill value={text} onChange={this.handleChange} />
  }

  loadingComponent() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Card className="card-calendar">
              <CardBody>
                <h1 className="menu-title">Loading...</h1>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  editSpecialComponent() {
    const selectedSpecial = this.props.specials[this.state.selectedSpecialIndex]
    const text = this.state.title ? this.state.title : this.props.specials[this.state.selectedSpecialIndex].title
    const setSpecial = (e) => this.setState({ selectedSpecialIndex: parseInt(e.target.id)}, () => { 
      this.setState({
        title: this.props.specials[this.state.selectedSpecialIndex].title,
        text: this.props.specials[this.state.selectedSpecialIndex].body
      }) 
    })

    return (
      <div className="content">
        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <UncontrolledDropdown className="menu-dropdown-container">
              <DropdownToggle caret size="lg">
                Select Special
              </DropdownToggle>
              <DropdownMenu>
                {this.props.specials.map((special, index) => (
                  <DropdownItem key={special.id} id={index} onClick={setSpecial}>{ `${special.title} (${special.label})` }</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <Button color="danger" size="lg" onClick={this.deleteSpecialModal}>
              Delete Special
            </Button>
            <Button className="pull-right" color="primary" size="lg" float="right" onClick={this.specialModal}>
              Create new Special
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Card className="card-calendar">
              <CardBody>
                <Button className="absolute-right add-item-btn" color="info" size="md" onClick={this.saveSpecial}>
                  Save
                </Button>
                <div className="text-center">
                  <CardTitle>
                    <Input placeholder="Special Title" className="special-title" onChange={e => this.handleTitleChange(e.target.value)} value={text}/>
                  </CardTitle>
                  <h5 className="text-muted">{selectedSpecial.day_of_week}</h5>
                  <Badge color="primary" pill>{selectedSpecial.label}</Badge>
                </div>
                { this.renderWYSIWYG() }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    return (
      <div className="main-panel">
        { this.props.specials ? this.editSpecialComponent() : this.loadingComponent() }
        <DeleteModal
          onSubmit={ () => this.deleteSpecial() }
          toggleModal={ this.deleteSpecialModal }
          modalOpen={ this.state.deleteSpecialModalOpen }
          message="You would like to delete this special?"
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, {...actions})(Specials);