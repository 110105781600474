import React from "react";
import Datetime from 'react-datetime';
import{ Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Row, Col } from 'reactstrap';
import moment from "moment";
import Button from "../CtButton";
import ImageUpload from "../ImageUpload"
import { DirectUploadProvider } from 'react-activestorage-provider'

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: {
        "title": "",
        "stateTime": "",
        "endTime": ""
      },
      event: {
        "title": "",
        "startTime": null,
        "endTime": null,
        "description": ""
      }
    }

    this.registerNotEmpty = this.registerNotEmpty.bind(this);
  }

  registerNotEmpty(e, state) {
    var event = this.state.event;
    var register = this.state.register;
    if(moment.isMoment(e)) {
      event[state] = e
      e.isValid() ? register[state] = "has-success" : register[state] = "has-danger";
    } else {
      event[state] = e.target.value;
    }
    this.setState({ register, event });
  }

  formSubmit(e, event) {
    console.log(event)
    e.preventDefault()
    var newEvent = {
      "name": event["title"],
      "description": event["description"],
      "start_time": event["startTime"].local().format(),
      "end_time": event["endTime"].local().format()
    }
    this.props.onSubmit({"event": newEvent})
    this.props.toggleModal()
  }

  test(signedIDs) {
    console.log(signedIDs)
  }

  render() {
    // TODO: Image upload's not working
    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.props.toggleModal}>
        <Form onSubmit={ e => this.formSubmit(e, this.state.event) }>
          <ModalHeader className="justify-content-center" toggle={this.props.toggleModal}>
            <FormGroup className={this.state.register.title}>
              <Input placeholder="Add Title" className="text-center" onChange={e => this.registerNotEmpty(e, "title")} value={this.state.event.title}/>
            </FormGroup>
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col xs="6">
                <FormGroup className={"mr-1 " + this.state.register.startTime}>
                  <Datetime inputProps={{placeholder:"Add start time"}} onChange={e => this.registerNotEmpty(e, "startTime")} value={this.state.event.startTime}/>
                </FormGroup>
                <FormGroup className={"ml-1 " + this.state.register.endTime}>
                  <Datetime inputProps={{placeholder:"Add end time"}} onChange={e => this.registerNotEmpty(e, "endTime")} value={this.state.event.endTime}/>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <DirectUploadProvider onSuccess={this.test} render={ () => <ImageUpload /> }/>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className={"has-label"}>
              <Input type="textarea" name="text" placeholder="Add description" onChange={e => this.registerNotEmpty(e, "description")} value={this.state.event.description}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggleModal}>
                Close
            </Button>
            <Button color="primary" type="submit">
              Add
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}