import React from "react";
import _ from 'lodash'
import{ Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Button from "../CtButton";

const TAP_TYPES = ["local", "domestic", "imported"]

export default class TapModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: {
        "name": "",
        "brewery": "",
        "beer_type": ""
      },
      tap: {
        "name": "",
        "brewery": "",
        "beer_type": ""
      }
    }

    this.registerNotEmpty = this.registerNotEmpty.bind(this);
  }

  registerNotEmpty(e, state) {
    var tap = this.state.tap;
    var register = this.state.register;
    tap[state] = e.target.value;
    this.setState({ register, tap });
  }

  formSubmit(e) {
    e.preventDefault()
    this.props.setTap(this.state.tap)
    this.props.toggleModal()
  }

  render() {
    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.props.toggleModal}>
        <Form onSubmit={ e => this.formSubmit(e, this.state.event) }>
          <ModalHeader className="justify-content-center" toggle={this.props.toggleModal}>
            <FormGroup className={this.state.register.name}>
              <Input placeholder="Add Tap" autoComplete="off" className="text-center" onChange={e => this.registerNotEmpty(e, "name")} value={this.state.tap.name}/>
            </FormGroup>
          </ModalHeader>
          <ModalBody>
            <FormGroup className={"has-label"}>
              <Input name="text" autoComplete="off" placeholder="Add Brewery" onChange={e => this.registerNotEmpty(e, "brewery")} value={this.state.tap.brewery}/>
            </FormGroup>
            <FormGroup className={"has-label"}>
              <UncontrolledDropdown>
                <DropdownToggle caret size="md">
                  {this.state.tap.beer_type === "" ? "Beer Type" : _.upperFirst(this.state.tap.beer_type)}
                </DropdownToggle>
                <DropdownMenu>
                  {TAP_TYPES.map((type, index) => (
                    <DropdownItem key={index} id={type} onClick={() => this.setState({tap: { ...this.state.tap, "beer_type": type}})}>{_.upperFirst(type)}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggleModal}>
                Close
            </Button>
            <Button color="primary" type="submit">
              Add
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
