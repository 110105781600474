import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actions from '../actions'
import Button from './CtButton'
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap';
import MenuModal from './modals/MenuModal'
import ItemModal from './modals/ItemModal'
import DeleteModal from './modals/DeleteModal'

const mapStateToProps = state => {
  return {
    current_location: state.auth.current_location,
    ...state.app
  }
}

class Menus extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedMenuIndex: 0,
      selectedItemID: null,
      menuModalOpen: false,
      itemModalOpen: false,
      deleteMenuModalOpen: false,
      deleteItemModalOpen: false
    }
    this.loadingComponent = this.loadingComponent.bind(this);
    this.menuModal = this.menuModal.bind(this);
    this.itemModal = this.itemModal.bind(this);
    this.deleteMenuModal = this.deleteMenuModal.bind(this);
    this.deleteItemModal = this.deleteItemModal.bind(this);
    this.menuComponent = this.menuComponent.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.current_location !== this.props.current_location) {
      this.props.loadMenus()
    }
  }

  componentDidMount(){
    this.props.loadMenus()
  }

  menuModal() {
    this.setState({ menuModalOpen: !this.state.menuModalOpen });
  }

  itemModal() {
    this.setState({ itemModalOpen: !this.state.itemModalOpen });
  }

  deleteMenuModal() {
    this.setState({ deleteMenuModalOpen: !this.state.deleteMenuModalOpen });
  }

  deleteItemModal(itemID = null) {
    if( _.isNumber(itemID) ){
      this.setState({
        selectedItemID: itemID,
        deleteItemModalOpen: !this.state.deleteItemModalOpen 
      });
    } else {
      this.setState({ deleteItemModalOpen: !this.state.deleteItemModalOpen });
    }
  }

  deleteMenu() {
    this.props.deleteMenu(this.props.menus[this.state.selectedMenuIndex].id)
    this.setState({ selectedMenuIndex: 0 })
  }

  renderTable(menu) {
    const fireModal = (id) => this.deleteItemModal(id)
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Item</th>
            <th>Description</th>
            <th className="text-right">Price</th>
            <th className="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {menu.items.map(function(item){
            return (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.description}</td>
                <td className="text-right">{item.price}</td>
                <td className="text-right">
                  <Button icon color="info" size="sm" id="item-edit">
                    <i className="now-ui-icons ui-2_settings-90"></i>
                  </Button>{` `}
                  <UncontrolledTooltip placement="top" target="item-edit" delay={{"show": 500, "hide": 0}}>Edit Item</UncontrolledTooltip>
                  <Button icon color="danger" size="sm" id="item-delete" onClick={ () => fireModal(item.id) }>
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </Button>
                  <UncontrolledTooltip placement="top" target="item-delete" delay={{"show": 500, "hide": 0}}>Delete Item</UncontrolledTooltip>
                </td>
              </tr>
            )}
          )}
        </tbody>
      </Table>
    )
  }

  createMenu() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Card className="card-calendar">
              <CardBody>
                <h1 className="menu-title">Create your first menu</h1>
                <Button color="primary" size="lg" onClick={this.menuModal}>
                  Create a menu <i className="now-ui-icons ui-1_simple-add"></i>
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  loadingComponent() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Card className="card-calendar">
              <CardBody>
                <h1 className="menu-title">Loading...</h1>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  menuComponent() {
    if(this.props.menus.length < 1) {
      return this.createMenu()
    }

    const selectedMenu = this.props.menus[this.state.selectedMenuIndex]
    const setMenu = (e) => this.setState({selectedMenuIndex: parseInt(e.target.id) })

    return (
      <div className="content">
        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <UncontrolledDropdown className="menu-dropdown-container">
              <DropdownToggle caret size="lg">
                {selectedMenu.title}
              </DropdownToggle>
              <DropdownMenu>
                {this.props.menus.map((menu, index) => (
                  <DropdownItem key={menu.id} id={index} onClick={setMenu}>{menu.title}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <Button color="danger" size="lg" onClick={this.deleteMenuModal}>
              Delete Menu
            </Button>
            <Button className="pull-right" color="primary" size="lg" float="right" onClick={this.menuModal}>
              New Menu <i className="now-ui-icons ui-1_simple-add"></i>
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={10} className="ml-auto mr-auto">
            <Card className="card-calendar">
              <CardBody>
                <Button className="absolute-right add-item-btn" color="primary" size="lg" onClick={this.itemModal}>
                  Add Item <i className="now-ui-icons ui-1_simple-add"></i>
                </Button>
                <h1 className="menu-title">{selectedMenu.title}</h1>
                <h5 className="text-muted text-center">{selectedMenu.description}</h5>
                { this.renderTable(selectedMenu) }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    return (
      <div className="main-panel">
        { this.props.menus ? this.menuComponent() : this.loadingComponent() }
        <MenuModal
          onSubmit={ this.props.setMenu }
          toggleModal={ this.menuModal }
          modalOpen={ this.state.menuModalOpen }
        />
        <DeleteModal
          onSubmit={ () => this.deleteMenu() }
          toggleModal={ this.deleteMenuModal }
          modalOpen={ this.state.deleteMenuModalOpen }
          message="You would like to delete this menu? Doing so will also remove all items belonging to the menu."
        />
        <DeleteModal
          onSubmit={ () => this.props.deleteItem(this.state.selectedItemID) }
          toggleModal={ this.deleteItemModal }
          modalOpen={ this.state.deleteItemModalOpen }
          message="You would like to delete this menu item?"
        />
        { this.props.menus && 
          <ItemModal
            onSubmit={ this.props.setItem }
            toggleModal={ this.itemModal }
            modalOpen={ this.state.itemModalOpen }
            parentMenu={ this.props.menus[this.state.selectedMenuIndex] }
          />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, {...actions})(Menus);