import React from "react";
import _ from 'lodash';
import { Nav } from "reactstrap";
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import NotificationAlert from "react-notification-alert";
import Button from "./CtButton";
import * as actions from '../actions'
import fontawesome from "@fortawesome/fontawesome";
import { faBeer } from "@fortawesome/free-solid-svg-icons";

fontawesome.library.add(faBeer);

const routes = [
  {
    "path": "/",
    "name": "Home",
    "icon": "now-ui-icons design_app"
  },
  {
    "path": "/events",
    "name": "Events",
    "icon": "now-ui-icons ui-1_calendar-60"
  },
  {
    "path": "/menus",
    "name": "Menus",
    "icon": "now-ui-icons education_agenda-bookmark"
  },
  {
    "path": "/taps",
    "name": "Taps",
    "icon": "fas fa-beer"
  },
  {
    "path": "/specials",
    "name": "Specials",
    "icon": "fas fa-dollar-sign"
  }
]

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openHome: this.activeRoute("/") !== "" ? true : false,
      openEvents: this.activeRoute("/events") !== "" ? true : false,
      openMenus: this.activeRoute("/menus") !== "" ? true : false,
    };
    this.activeRoute = this.activeRoute.bind(this);
    this.minimizeSidebar = this.minimizeSidebar.bind(this);
  }

  activeRoute(routeName) {
    let routeArray = _.split(this.props.location.pathname, '/');
    if(routeArray.length < 2) {
      return this.props.location.pathname === "/" ? "active" : "";
    }
    return routeName === "/" + routeArray[1] ? "active" : "";
  }

  minimizeSidebar() {
    // this.props.toggleSidebar()
    // var message = "Sidebar mini ";
    // if (document.body.classList.contains("sidebar-mini")) {
    //   message += "deactivated...";
    // } else {
    //   message += "activated...";
    // }
    // document.body.classList.toggle("sidebar-mini", this.props.sidebarOpen);
    // var options = {
    //   place: "tr",
    //   message: message,
    //   type: "info",
    //   icon: "now-ui-icons ui-1_bell-53",
    //   autoDismiss: 7
    // };
    // this.refs.notificationAlert.notificationAlert(options);
  }

  componentDidMount() {
    library.add(fas);
    document.body.classList.toggle("sidebar-mini", !this.props.sidebarOpen);
    if (navigator.platform.indexOf("Win") > -1) {
      // ps = new PerfectScrollbar(this.refs.sidebar, {
      //   suppressScrollX: true,
      //   suppressScrollY: false
      // });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps.destroy();
    }
    // to stop the warning of calling setState of unmounted component
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  createNavLinks() {
    return routes.map((route) =>
      <li className={this.activeRoute(route.path)}  key={route.path}>
        <NavLink
          to={route.path}
          className="nav-link"
          activeClassName="active"
        >
          <i className={ route.icon }/>
          <p>{route.name}</p>
        </NavLink>
      </li>
    )
  }
  render() {
    if (!this.props.authenticated) {
      return null
    }
    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <div className="sidebar" data-color="blue">
          <div className="logo">
            <a href="https://www.creative-tim.com" className="simple-text logo-mini">
              <div className="logo-img">
                <img src="https://demos.creative-tim.com/now-ui-dashboard-pro-react/static/media/logo-white.eec7c7f6.svg" alt="react-logo" />
              </div>
            </a>
            <a href="/" className="simple-text logo-normal">
            Menu
            </a>
            <div className="navbar-minimize">
              <Button
                simple
                neutral
                icon
                round
                id="minimizeSidebar"
                onClick={this.minimizeSidebar}
              >
                <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
              </Button>
            </div>
          </div>
          <div className="sidebar-wrapper">
          <Nav>
            { this.createNavLinks() }
          </Nav>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, {...actions})(SideBar);
