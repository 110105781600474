import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { signInAction } from '../actions/auth';
import { connect } from 'react-redux';
import Button from './CtButton'
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

class Login extends React.Component {
  submit = (values) => {
    this.props.signInAction(values, this.props.history);
  }

  errorMessage() {
    if (this.props.errorMessage) {
      return (
        <div className="info-red">
          {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="form">
        <div className="full-page-content">
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                <form onSubmit={ handleSubmit(this.submit) }>
                <Card className="card-login card-plain">
                  <CardBody>
                    <Field name="email"
                          component="input"
                          type="text"
                          className="login-input"
                          placeholder="Email" 
                    />
                    <Field name="password" 
                          component="input"
                          type="password" 
                          className="login-input"
                          placeholder="Password" 
                    />
                  {this.errorMessage()}
                  </CardBody>
                  <CardFooter>
                    <Button type="submit" color="primary" round block>Log In</Button>
                  </CardFooter>
                </Card>
                </form>
              </Col>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.error };
}

const reduxFormSignin = reduxForm({
  form: 'login'
})(Login);

export default connect(mapStateToProps, {signInAction})(reduxFormSignin);