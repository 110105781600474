import React from 'react';
import { connect } from 'react-redux'
import { signOutAction, setLocation } from '../actions/auth'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav } from 'reactstrap';

class NavBar extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        isOpen: false,
        dropdownOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }

  componentDidMount(){
    if(this.props.current_location){
      document.title = this.props.current_location.name
    } else {
      document.title = "MenuMate"
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.current_location !== this.props.current_location) {
      document.title = this.props.current_location.name
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  dropdownToggle(e){
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render(){
    if (!this.props.authenticated) {
      return null
    }
    return(
      <div>
        <Navbar color="dark" dark expand="md" fixed={'top'}>
          <NavbarBrand>
          <span className="logo">
            <img src="https://demos.creative-tim.com/now-ui-dashboard-pro-react/static/media/logo-white.eec7c7f6.svg" height="25" width="25" alt={this.props.current_location.name} />
          </span>
          {this.props.current_location.name}
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {this.props.user.email} <i className="now-ui-icons users_single-02"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  {this.props.user.locations.map((location, index) => (
                    <DropdownItem
                      disabled={location.id === this.props.current_location.id}
                      onClick={ () => this.props.setLocation(location) }
                      key={location.id}
                      id={index}
                    >
                      {location.name}
                    </DropdownItem>
                  ))}
                  <DropdownItem divider />
                  <DropdownItem>
                    Profile
                  </DropdownItem>
                  <DropdownItem>
                    Settings
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick= { () => this.props.signOutAction() }>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    authenticated: state.auth.authenticated,
    current_location: state.auth.current_location
  };
}

export default connect(mapStateToProps, {signOutAction, setLocation})(NavBar);
